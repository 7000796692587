
import moment from "moment";
import axios from "axios";
        
class RequestBuilder {
    _entity = 'unknown';
    _select = [];
    _virtuals = [];
    _collects = [];
    _orders = [];
    _filters = [];
    _filterParams = [];
    _filterAdd = [];
    _page = null;
    _onPage = null;
    _url = null;

    selects(v){
        if(typeof this._select.find((el)=> el===this._entity+'.'+v)==='undefined')
            this._select.push(this._entity+'.'+v)
        return this;
    }

    virtual(v, ...params){
        let vRequest = v;
        if(params.length>0)
            vRequest +='|'+params.join(',')
        if(typeof this._virtuals.find((el)=> el===vRequest)==='undefined')
            this._virtuals.push(vRequest)
        return this;
    }

    collect(v){
        if(typeof this._collects.find((el)=> el===v)==='undefined')
            this._collects.push(v)
        return this;
    }

    //isAsc - true = 'asc', false = 'desc'
    order(field, isAsc){
        this._orders.push([field, isAsc])
        return this;
    }
    
    orderFromTable(sortFields){
        sortFields.forEach(v=>{
            this._orders.push([v.field, v.isAsc])
        })
        return this;
    }

    filterAnd(v, ...params){
        this._filters.push(' AND ('+v+')')
        params.forEach(v=>{
            this._filterParams.push(v)
        });
        return this;
    }

    filterOr(v, ...params){
        this._filters.push(' OR ('+v+')')
        params.forEach(v=>{
            this._filterParams.push(v)
        });
        return this;
    }
    
    filterAdd(v){
        if(typeof this._filterAdd.find((el)=> el===v)==='undefined')
            this._filterAdd.push(v)
        return this;
    }

    page(page){
        this._page = page;
        return this;
    }
    onPage(onPage){
        this._onPage = onPage;
        return this;
    }

    genUrl(){
        let url = location.protocol+'//'+location.host+'/napi/entity/'+this._entity+'/';
        const query = new URLSearchParams();

        if(this._select.length>0){
            if(query.size===0) url +='?'
            this._select.forEach(v=>{
                query.append('select[]', v)
            })
        }

        if(this._virtuals.length>0){
            if(query.size===0) url +='?'
            this._virtuals.forEach(v=>{
                query.append('virtual[]', v)
            })
        }
        
        if(this._collects.length>0){
            if(query.size===0) url +='?'
            this._collects.forEach(v=>{
                query.append('collect[]', v)
            })
        }

        if(this._orders.length>0){
            if(query.size===0) url +='?'
            this._orders.forEach(v=>{
                query.append('order[]', this._entity+'.'+v[0]+' '+(v[1] ? 'asc' : 'desc'))
            })
        }

        if(this._filters.length>0){
            let q = '';
            this._filters.forEach(v=>{
                q += v
            })

            q = q.substring(q.indexOf('('), q.length)
            let index = 1;
            let qf = '';
            let arr = q.split('?');
            arr.forEach((v,k)=>{
                qf += k<arr.length-1 ? v+'?'+index++ : v;
            });
            query.append('filter', qf)

            this._filterParams.forEach(v=>{
                if(Array.isArray(v)){
                    query.append('filterParam[]', v.join(','))
                } else if(v instanceof moment){
                    query.append('filterParam[]', v.format('YYYY-MM-DD HH:mm:ss'))
                } else {
                    query.append('filterParam[]', v)
                }

            })
        }

        if(this._filterAdd.length>0){
            if(query.size===0) url +='?'
            this._filterAdd.forEach(v=>{
                query.append('filterAdd[]', v)
            })
        }
        
        if(this._page!==null)
            query.append('page', this._page)
        if(this._onPage!==null)
            query.append('onPage', this._onPage)

        this._url = url+query.toString();
        return this._url;
    }
        
    _stateCallbacks = []
    addStateCallback(v){
        this._stateCallbacks.push(v);
        return this;
    }
    request(successCallback=null, errorCallback=null){
        this.genUrl();
        this._stateCallbacks.forEach(v=>{
            v({
                items: [],
                count: 0,
                collect: {},
                loading: true,
                success: false,
                error: false,
                errorMessage: null,
            })
        })
        axios.get(this._url)
            .then((r)=>{
                this._stateCallbacks.forEach(v=>{
                    v({
                        items: r.data.items,
                        count: r.data.count,
                        collect: r.data.collect,
                        loading: false,
                        success: true,
                        error: false,
                        errorMessage: null,
                    })
                })
                if(successCallback!==null)
                    successCallback(r.data);
            })
            .catch((e)=>{
                this._stateCallbacks.forEach(v=>{
                    v({
                        items: [],
                        count: 0,
                        collect: {},
                        loading: false,
                        success: false,
                        error: true,
                        errorMessage: e,
                    })
                })
                if(errorCallback!==null)
                    errorCallback(e);
            });
        return this;
    }
}
export class Entity_CallEntry extends RequestBuilder{
    _entity = 'callEntry'
    static entity = 'callEntry'
    static disconnectReason = 'disconnectReason' // type smallint
    static disconnectReasonMessage = 'disconnectReasonMessage' // type string
    static fromExtension = 'fromExtension' // type string
    static fromNumber = 'fromNumber' // type string
    static fromPhoneNumber = 'fromPhoneNumber' // type bigint
    static id = 'id' // type integer
    static location = 'location' // type string
    static recordFile = 'recordFile' // type string
    static sid = 'sid' // type string
    static state = 'state' // type string
    static toExtension = 'toExtension' // type string
    static toLineNumber = 'toLineNumber' // type string
    static toNumber = 'toNumber' // type string
    static toPhoneNumber = 'toPhoneNumber' // type bigint
    static callEntryStates = 'callEntryStates' // type callEntryState
    static callGroup = 'callGroup' // type callGroup
    static fromCallEntry = 'fromCallEntry' // type callEntry
    static toCallEntrys = 'toCallEntrys' // type callEntry
    static userFrom = 'userFrom' // type user
    static userTo = 'userTo' // type user
}

export class Entity_CallEntryState extends RequestBuilder{
    _entity = 'callEntryState'
    static entity = 'callEntryState'
    static id = 'id' // type integer
    static seq = 'seq' // type integer
    static state = 'state' // type string
    static timestamp = 'timestamp' // type datetime
    static callEntry = 'callEntry' // type callEntry
}

export class Entity_CallGroup extends RequestBuilder{
    _entity = 'callGroup'
    static entity = 'callGroup'
    static callDirection = 'callDirection' // type smallint
    static callRecordFile = 'callRecordFile' // type string
    static comment = 'comment' // type string
    static createTime = 'createTime' // type datetime
    static disconnectReason = 'disconnectReason' // type smallint
    static disconnectReasonMessage = 'disconnectReasonMessage' // type string
    static endDuration = 'endDuration' // type integer
    static entryResult = 'entryResult' // type smallint
    static forwardDuration = 'forwardDuration' // type integer
    static fromExtension = 'fromExtension' // type string
    static fromNumber = 'fromNumber' // type string
    static fromPhoneNumber = 'fromPhoneNumber' // type bigint
    static id = 'id' // type integer
    static lineNumber = 'lineNumber' // type string
    static request = 'request' // type boolean
    static sid = 'sid' // type string
    static status = 'status' // type string
    static talkDuration = 'talkDuration' // type integer
    static talkWaitDuration = 'talkWaitDuration' // type integer
    static toExtension = 'toExtension' // type string
    static toNumber = 'toNumber' // type string
    static toPhoneNumber = 'toPhoneNumber' // type bigint
    static userClientState = 'userClientState' // type string
    static callEntrys = 'callEntrys' // type callEntry
    static callResult = 'callResult' // type callResult
    static callTarget = 'callTarget' // type callTarget
    static userClient = 'userClient' // type user
    static userFrom = 'userFrom' // type user
    static userTo = 'userTo' // type user
    static visits = 'visits' // type visit
    static collect_indicators = 'indicators'
}

export class Entity_CallResult extends RequestBuilder{
    _entity = 'callResult'
    static entity = 'callResult'
    static comment = 'comment' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static type = 'type' // type string
    static callGroup = 'callGroup' // type callGroup
    static callTargets = 'callTargets' // type callTarget
}

export class Entity_CallTarget extends RequestBuilder{
    _entity = 'callTarget'
    static entity = 'callTarget'
    static comment = 'comment' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static callGroup = 'callGroup' // type callGroup
    static callResults = 'callResults' // type callResult
}

export class Entity_Com extends RequestBuilder{
    _entity = 'com'
    static entity = 'com'
    static id = 'id' // type integer
    static name = 'name' // type string
    static comBranchs = 'comBranchs' // type comBranch
    static comUserCats = 'comUserCats' // type comUserCat
}

export class Entity_ComBranch extends RequestBuilder{
    _entity = 'comBranch'
    static entity = 'comBranch'
    static color = 'color' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static workPlan = 'workPlan' // type json
    static com = 'com' // type com
    static comBuildings = 'comBuildings' // type comBuilding
    static comDivs = 'comDivs' // type comDiv
    static mediaTemplates = 'mediaTemplates' // type mediaTemplate
    static salarys = 'salarys' // type salary
    static serviceCats = 'serviceCats' // type serviceCat
    static services = 'services' // type service
}

export class Entity_ComBuilding extends RequestBuilder{
    _entity = 'comBuilding'
    static entity = 'comBuilding'
    static des = 'des' // type string
    static id = 'id' // type integer
    static lat = 'lat' // type decimal
    static lon = 'lon' // type decimal
    static street = 'street' // type string
    static comBranch = 'comBranch' // type comBranch
    static comFloors = 'comFloors' // type comFloor
}

export class Entity_ComDiv extends RequestBuilder{
    _entity = 'comDiv'
    static entity = 'comDiv'
    static id = 'id' // type integer
    static name = 'name' // type string
    static comBranch = 'comBranch' // type comBranch
    static comDiv = 'comDiv' // type comDiv
    static comDivs = 'comDivs' // type comDiv
    static comPlaces = 'comPlaces' // type comPlace
    static serviceCat = 'serviceCat' // type serviceCat
    static userSpecs = 'userSpecs' // type userSpec
}

export class Entity_ComFloor extends RequestBuilder{
    _entity = 'comFloor'
    static entity = 'comFloor'
    static id = 'id' // type integer
    static name = 'name' // type string
    static comBuilding = 'comBuilding' // type comBuilding
    static comRooms = 'comRooms' // type comRoom
}

export class Entity_ComPlace extends RequestBuilder{
    _entity = 'comPlace'
    static entity = 'comPlace'
    static id = 'id' // type integer
    static name = 'name' // type string
    static comDiv = 'comDiv' // type comDiv
    static comRoom = 'comRoom' // type comRoom
    static userSpecPlans = 'userSpecPlans' // type userSpecPlan
    static visits = 'visits' // type visit
    static virtual_comPlaceLoad = 'comPlaceLoad'
}

export class Entity_ComRoom extends RequestBuilder{
    _entity = 'comRoom'
    static entity = 'comRoom'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sid = 'sid' // type string
    static sort = 'sort' // type integer
    static comFloor = 'comFloor' // type comFloor
    static comPlaces = 'comPlaces' // type comPlace
}

export class Entity_ComUserCat extends RequestBuilder{
    _entity = 'comUserCat'
    static entity = 'comUserCat'
    static id = 'id' // type integer
    static name = 'name' // type string
    static com = 'com' // type com
    static userSpecs = 'userSpecs' // type userSpec
}

export class Entity_Dialog extends RequestBuilder{
    _entity = 'dialog'
    static entity = 'dialog'
    static id = 'id' // type integer
    static name = 'name' // type string
    static dialogMessageLast = 'dialogMessageLast' // type dialogMessage
    static dialogMessages = 'dialogMessages' // type dialogMessage
    static users = 'users' // type user
}

export class Entity_DialogMessage extends RequestBuilder{
    _entity = 'dialogMessage'
    static entity = 'dialogMessage'
    static created = 'created' // type datetime
    static id = 'id' // type integer
    static read = 'read' // type datetime
    static text = 'text' // type string
    static dialog = 'dialog' // type dialog
    static dialogMessageAttachs = 'dialogMessageAttachs' // type dialogMessageAttach
    static user = 'user' // type user
}

export class Entity_DialogMessageAttach extends RequestBuilder{
    _entity = 'dialogMessageAttach'
    static entity = 'dialogMessageAttach'
    static id = 'id' // type integer
    static url = 'url' // type string
    static dialogMessage = 'dialogMessage' // type dialogMessage
}

export class Entity_Direction extends RequestBuilder{
    _entity = 'direction'
    static entity = 'direction'
    static id = 'id' // type integer
    static name = 'name' // type string
    static directionCat = 'directionCat' // type directionCat
    static medPlans = 'medPlans' // type medPlan
    static serviceCats = 'serviceCats' // type serviceCat
    static services = 'services' // type service
}

export class Entity_DirectionCat extends RequestBuilder{
    _entity = 'directionCat'
    static entity = 'directionCat'
    static id = 'id' // type integer
    static isCanDelete = 'isCanDelete' // type boolean
    static name = 'name' // type string
    static directions = 'directions' // type direction
}

export class Entity_AccountPlan extends RequestBuilder{
    _entity = 'accountPlan'
    static entity = 'accountPlan'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sid = 'sid' // type string
    static type = 'type' // type string
    static analyticTypes = 'analyticTypes' // type analyticType
    static operationTypesCredits = 'operationTypesCredits' // type operationType
    static operationTypesDebets = 'operationTypesDebets' // type operationType
}

export class Entity_AnalyticType extends RequestBuilder{
    _entity = 'analyticType'
    static entity = 'analyticType'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static accountPlans = 'accountPlans' // type accountPlan
    static analyticValueCredits = 'analyticValueCredits' // type analyticValueCredit
    static analyticValueDebets = 'analyticValueDebets' // type analyticValueDebet
    static analyticValueGroups = 'analyticValueGroups' // type analyticValueGroup
    static operationGroupTypes = 'operationGroupTypes' // type operationGroupType
}

export class Entity_AnalyticValueCredit extends RequestBuilder{
    _entity = 'analyticValueCredit'
    static entity = 'analyticValueCredit'
    static id = 'id' // type integer
    static value = 'value' // type string
    static valueName = 'valueName' // type string
    static analyticTypeCredit = 'analyticTypeCredit' // type analyticType
    static operationCredit = 'operationCredit' // type operation
}

export class Entity_AnalyticValueDebet extends RequestBuilder{
    _entity = 'analyticValueDebet'
    static entity = 'analyticValueDebet'
    static id = 'id' // type integer
    static value = 'value' // type string
    static valueName = 'valueName' // type string
    static analyticTypeDebet = 'analyticTypeDebet' // type analyticType
    static operationDebet = 'operationDebet' // type operation
}

export class Entity_AnalyticValueGroup extends RequestBuilder{
    _entity = 'analyticValueGroup'
    static entity = 'analyticValueGroup'
    static id = 'id' // type integer
    static value = 'value' // type string
    static valueName = 'valueName' // type string
    static analyticType = 'analyticType' // type analyticType
    static operationGroup = 'operationGroup' // type operationGroup
}

export class Entity_Operation extends RequestBuilder{
    _entity = 'operation'
    static entity = 'operation'
    static amount = 'amount' // type decimal
    static count = 'count' // type decimal
    static discount = 'discount' // type decimal
    static id = 'id' // type integer
    static analyticValueCredits = 'analyticValueCredits' // type analyticValueCredit
    static analyticValueDebets = 'analyticValueDebets' // type analyticValueDebet
    static operationGroup = 'operationGroup' // type operationGroup
    static operationType = 'operationType' // type operationType
}

export class Entity_OperationGroup extends RequestBuilder{
    _entity = 'operationGroup'
    static entity = 'operationGroup'
    static comment = 'comment' // type string
    static created = 'created' // type datetime
    static id = 'id' // type integer
    static analyticValueGroups = 'analyticValueGroups' // type analyticValueGroup
    static fixed = 'fixed' // type operationGroup
    static fixedBy = 'fixedBy' // type operationGroup
    static operationGroupType = 'operationGroupType' // type operationGroupType
    static operations = 'operations' // type operation
    static reverse = 'reverse' // type operationGroup
    static reverseBy = 'reverseBy' // type operationGroup
}

export class Entity_OperationGroupType extends RequestBuilder{
    _entity = 'operationGroupType'
    static entity = 'operationGroupType'
    static id = 'id' // type integer
    static name = 'name' // type string
    static analyticTypes = 'analyticTypes' // type analyticType
    static operationGroups = 'operationGroups' // type operationGroup
    static operationTypes = 'operationTypes' // type operationType
    static reverseBys = 'reverseBys' // type operationGroupType
    static reverses = 'reverses' // type operationGroupType
}

export class Entity_OperationType extends RequestBuilder{
    _entity = 'operationType'
    static entity = 'operationType'
    static comment = 'comment' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static payType = 'payType' // type string
    static sourceType = 'sourceType' // type string
    static accountPlanCredit = 'accountPlanCredit' // type accountPlan
    static accountPlanDebet = 'accountPlanDebet' // type accountPlan
    static operationGroupType = 'operationGroupType' // type operationGroupType
    static operations = 'operations' // type operation
}

export class Entity_Icd extends RequestBuilder{
    _entity = 'icd'
    static entity = 'icd'
    static code = 'code' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static icd = 'icd' // type icd
    static icds = 'icds' // type icd
}

export class Entity_Insur extends RequestBuilder{
    _entity = 'insur'
    static entity = 'insur'
    static comment = 'comment' // type string
    static contract = 'contract' // type boolean
    static contractNumber = 'contractNumber' // type string
    static date = 'date' // type date
    static dms = 'dms' // type boolean
    static end = 'end' // type date
    static fullName = 'fullName' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static oms = 'oms' // type boolean
    static pays = 'pays' // type pay
    static serviceInsurs = 'serviceInsurs' // type serviceInsur
    static userProfileDmss = 'userProfileDmss' // type userProfile
    static userProfileOmss = 'userProfileOmss' // type userProfile
    static visitServices = 'visitServices' // type visitService
}

export class Entity_Log extends RequestBuilder{
    _entity = 'log'
    static entity = 'log'
    static created = 'created' // type datetime
    static entityId = 'entityId' // type integer
    static entityName = 'entityName' // type string
    static id = 'id' // type integer
    static parentEntityId = 'parentEntityId' // type integer
    static parentEntityName = 'parentEntityName' // type string
    static text = 'text' // type string
    static type = 'type' // type string
    static user = 'user' // type user
}

export class Entity_MedPlan extends RequestBuilder{
    _entity = 'medPlan'
    static entity = 'medPlan'
    static achievedDate = 'achievedDate' // type datetime
    static begin = 'begin' // type datetime
    static comment = 'comment' // type string
    static end = 'end' // type datetime
    static id = 'id' // type integer
    static lastMenstrDate = 'lastMenstrDate' // type datetime
    static name = 'name' // type string
    static nextStepDate = 'nextStepDate' // type datetime
    static plannedDate = 'plannedDate' // type datetime
    static status = 'status' // type string
    static directions = 'directions' // type direction
    static user = 'user' // type user
    static userSpec = 'userSpec' // type user
    static visitServices = 'visitServices' // type visitService
    static virtual_isOverDate = 'isOverDate'
    static virtual_summary = 'summary'
    static filterAdd_overDate = 'overDate'
}

export class Entity_MedRecord extends RequestBuilder{
    _entity = 'medRecord'
    static entity = 'medRecord'
    static comment = 'comment' // type string
    static created = 'created' // type datetime
    static id = 'id' // type integer
    static isSent = 'isSent' // type boolean
    static name = 'name' // type string
    static status = 'status' // type string
    static medRecordTemplate = 'medRecordTemplate' // type medRecordTemplate
    static medRecordValues = 'medRecordValues' // type medRecordValue
    static user = 'user' // type user
    static userAuthor = 'userAuthor' // type user
}

export class Entity_MedRecordTemplate extends RequestBuilder{
    _entity = 'medRecordTemplate'
    static entity = 'medRecordTemplate'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static status = 'status' // type string
    static medRecordTemplateCategory = 'medRecordTemplateCategory' // type medRecordTemplateCategory
    static medRecordTemplateFieldGroups = 'medRecordTemplateFieldGroups' // type medRecordTemplateFieldGroup
    static medRecords = 'medRecords' // type medRecord
}

export class Entity_MedRecordTemplateCategory extends RequestBuilder{
    _entity = 'medRecordTemplateCategory'
    static entity = 'medRecordTemplateCategory'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static status = 'status' // type string
    static medRecordTemplates = 'medRecordTemplates' // type medRecordTemplate
    static spec = 'spec' // type spec
}

export class Entity_MedRecordTemplateField extends RequestBuilder{
    _entity = 'medRecordTemplateField'
    static entity = 'medRecordTemplateField'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static status = 'status' // type string
    static value = 'value' // type string
    static medRecordTemplateFieldGroup = 'medRecordTemplateFieldGroup' // type medRecordTemplateFieldGroup
    static medRecordTemplateFieldValues = 'medRecordTemplateFieldValues' // type medRecordTemplateFieldValue
    static medRecordValues = 'medRecordValues' // type medRecordValue
}

export class Entity_MedRecordTemplateFieldGroup extends RequestBuilder{
    _entity = 'medRecordTemplateFieldGroup'
    static entity = 'medRecordTemplateFieldGroup'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static status = 'status' // type string
    static medRecordTemplate = 'medRecordTemplate' // type medRecordTemplate
    static medRecordTemplateFields = 'medRecordTemplateFields' // type medRecordTemplateField
}

export class Entity_MedRecordTemplateFieldValue extends RequestBuilder{
    _entity = 'medRecordTemplateFieldValue'
    static entity = 'medRecordTemplateFieldValue'
    static id = 'id' // type integer
    static rangeEnd = 'rangeEnd' // type integer
    static rangeStart = 'rangeStart' // type integer
    static status = 'status' // type string
    static type = 'type' // type string
    static value = 'value' // type string
    static medRecordTemplateField = 'medRecordTemplateField' // type medRecordTemplateField
    static medRecordTemplateFieldValueOptions = 'medRecordTemplateFieldValueOptions' // type medRecordTemplateFieldValueOption
}

export class Entity_MedRecordTemplateFieldValueOption extends RequestBuilder{
    _entity = 'medRecordTemplateFieldValueOption'
    static entity = 'medRecordTemplateFieldValueOption'
    static id = 'id' // type integer
    static sort = 'sort' // type integer
    static status = 'status' // type string
    static value = 'value' // type string
    static medRecordTemplateFieldValue = 'medRecordTemplateFieldValue' // type medRecordTemplateFieldValue
}

export class Entity_MedRecordValue extends RequestBuilder{
    _entity = 'medRecordValue'
    static entity = 'medRecordValue'
    static id = 'id' // type integer
    static value = 'value' // type string
    static medRecord = 'medRecord' // type medRecord
    static medRecordTemplateField = 'medRecordTemplateField' // type medRecordTemplateField
}

export class Entity_Media extends RequestBuilder{
    _entity = 'media'
    static entity = 'media'
    static convertStatus = 'convertStatus' // type string
    static correctNumber = 'correctNumber' // type integer
    static created = 'created' // type datetime
    static des = 'des' // type string
    static fileName = 'fileName' // type string
    static id = 'id' // type integer
    static isSent = 'isSent' // type boolean
    static isView = 'isView' // type boolean
    static name = 'name' // type string
    static number = 'number' // type integer
    static status = 'status' // type string
    static type = 'type' // type string
    static uniqKey = 'uniqKey' // type string
    static mediaDir = 'mediaDir' // type mediaDir
    static user = 'user' // type user
    static userAuthor = 'userAuthor' // type user
    static visit = 'visit' // type visit
}

export class Entity_MediaDir extends RequestBuilder{
    _entity = 'mediaDir'
    static entity = 'mediaDir'
    static des = 'des' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static mediaTemplateCategorys = 'mediaTemplateCategorys' // type mediaTemplateCategory
    static mediaTemplates = 'mediaTemplates' // type mediaTemplate
    static medias = 'medias' // type media
}

export class Entity_MediaTemplate extends RequestBuilder{
    _entity = 'mediaTemplate'
    static entity = 'mediaTemplate'
    static copyCount = 'copyCount' // type smallint
    static created = 'created' // type datetime
    static des = 'des' // type string
    static fileName = 'fileName' // type string
    static id = 'id' // type integer
    static isCanPrintVisit = 'isCanPrintVisit' // type boolean
    static name = 'name' // type string
    static sort = 'sort' // type smallint
    static comBranch = 'comBranch' // type comBranch
    static mediaDir = 'mediaDir' // type mediaDir
    static mediaTemplateCategory = 'mediaTemplateCategory' // type mediaTemplateCategory
}

export class Entity_MediaTemplateCategory extends RequestBuilder{
    _entity = 'mediaTemplateCategory'
    static entity = 'mediaTemplateCategory'
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static status = 'status' // type string
    static mediaDir = 'mediaDir' // type mediaDir
    static mediaTemplates = 'mediaTemplates' // type mediaTemplate
    static parentCategory = 'parentCategory' // type mediaTemplateCategory
    static subcategories = 'subcategories' // type mediaTemplateCategory
}

export class Entity_Nomen extends RequestBuilder{
    _entity = 'nomen'
    static entity = 'nomen'
    static code = 'code' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static price = 'price' // type decimal
    static nomenCat = 'nomenCat' // type nomenCat
    static services = 'services' // type service
}

export class Entity_NomenCat extends RequestBuilder{
    _entity = 'nomenCat'
    static entity = 'nomenCat'
    static id = 'id' // type integer
    static name = 'name' // type string
    static noments = 'noments' // type nomen
}

export class Entity_Notif extends RequestBuilder{
    _entity = 'notif'
    static entity = 'notif'
    static created = 'created' // type datetime
    static data = 'data' // type json
    static error = 'error' // type string
    static fcmData = 'fcmData' // type json
    static id = 'id' // type integer
    static name = 'name' // type string
    static readed = 'readed' // type datetime
    static sended = 'sended' // type datetime
    static text = 'text' // type string
    static user = 'user' // type user
}

export class Entity_PassType extends RequestBuilder{
    _entity = 'passType'
    static entity = 'passType'
    static code = 'code' // type smallint
    static id = 'id' // type integer
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static userProfiles = 'userProfiles' // type userProfile
}

export class Entity_Pay extends RequestBuilder{
    _entity = 'pay'
    static entity = 'pay'
    static accountBalance = 'accountBalance' // type decimal
    static amount = 'amount' // type decimal
    static amountAccount = 'amountAccount' // type decimal
    static amountBankAccount = 'amountBankAccount' // type decimal
    static amountCard = 'amountCard' // type decimal
    static amountCash = 'amountCash' // type decimal
    static amountContract = 'amountContract' // type decimal
    static amountDms = 'amountDms' // type decimal
    static amountInsur = 'amountInsur' // type decimal
    static amountOms = 'amountOms' // type decimal
    static amountPayed = 'amountPayed' // type decimal
    static cancelDateTime = 'cancelDateTime' // type datetime
    static comment = 'comment' // type text
    static created = 'created' // type datetime
    static id = 'id' // type integer
    static isAcceptBankAccount = 'isAcceptBankAccount' // type boolean
    static isAcceptContract = 'isAcceptContract' // type boolean
    static isAcceptDms = 'isAcceptDms' // type boolean
    static isAcceptOms = 'isAcceptOms' // type boolean
    static kktName = 'kktName' // type string
    static slipCard = 'slipCard' // type string
    static terminalName = 'terminalName' // type string
    static tranId = 'tranId' // type string
    static type = 'type' // type string
    static insur = 'insur' // type insur
    static operatorUser = 'operatorUser' // type user
    static payChild = 'payChild' // type pay
    static payParent = 'payParent' // type pay
    static user = 'user' // type user
    static visitServices = 'visitServices' // type visitService
}

export class Entity_Referral extends RequestBuilder{
    _entity = 'referral'
    static entity = 'referral'
    static comment = 'comment' // type string
    static contractNumber = 'contractNumber' // type string
    static firstName = 'firstName' // type string
    static id = 'id' // type integer
    static lastName = 'lastName' // type string
    static name = 'name' // type string
    static phone = 'phone' // type bigint
    static secondName = 'secondName' // type string
    static status = 'status' // type string
    static refVisits = 'refVisits' // type visit
    static user = 'user' // type user
}

export class Entity_Review extends RequestBuilder{
    _entity = 'review'
    static entity = 'review'
    static des = 'des' // type string
    static id = 'id' // type integer
    static rate = 'rate' // type smallint
    static user = 'user' // type user
    static visit = 'visit' // type visit
}

export class Entity_Salary extends RequestBuilder{
    _entity = 'salary'
    static entity = 'salary'
    static begin = 'begin' // type date
    static comment = 'comment' // type string
    static createVisit = 'createVisit' // type decimal
    static createVisitMode = 'createVisitMode' // type string
    static id = 'id' // type integer
    static isRateServiceDiscount = 'isRateServiceDiscount' // type boolean
    static rate = 'rate' // type decimal
    static rateMax = 'rateMax' // type decimal
    static rateMaxMode = 'rateMaxMode' // type string
    static rateMode = 'rateMode' // type string
    static ref = 'ref' // type decimal
    static refMode = 'refMode' // type string
    static reg = 'reg' // type decimal
    static regMode = 'regMode' // type string
    static ren = 'ren' // type decimal
    static renMode = 'renMode' // type string
    static comBranch = 'comBranch' // type comBranch
    static salaryServices = 'salaryServices' // type salaryService
    static salaryTemplate = 'salaryTemplate' // type salaryTemplate
    static user = 'user' // type user
}

export class Entity_SalaryService extends RequestBuilder{
    _entity = 'salaryService'
    static entity = 'salaryService'
    static createVisit = 'createVisit' // type decimal
    static createVisitMode = 'createVisitMode' // type string
    static id = 'id' // type integer
    static ref = 'ref' // type decimal
    static refMode = 'refMode' // type string
    static reg = 'reg' // type decimal
    static regMode = 'regMode' // type string
    static ren = 'ren' // type decimal
    static renMode = 'renMode' // type string
    static salary = 'salary' // type salary
    static service = 'service' // type service
}

export class Entity_SalaryTemplate extends RequestBuilder{
    _entity = 'salaryTemplate'
    static entity = 'salaryTemplate'
    static comment = 'comment' // type string
    static createVisit = 'createVisit' // type decimal
    static createVisitMode = 'createVisitMode' // type string
    static id = 'id' // type integer
    static isRateServiceDiscount = 'isRateServiceDiscount' // type boolean
    static name = 'name' // type string
    static rate = 'rate' // type decimal
    static rateMax = 'rateMax' // type decimal
    static rateMaxMode = 'rateMaxMode' // type string
    static rateMode = 'rateMode' // type string
    static ref = 'ref' // type decimal
    static refMode = 'refMode' // type string
    static reg = 'reg' // type decimal
    static regMode = 'regMode' // type string
    static ren = 'ren' // type decimal
    static renMode = 'renMode' // type string
    static salaryTemplateServices = 'salaryTemplateServices' // type salaryTemplateService
    static salarys = 'salarys' // type salary
}

export class Entity_SalaryTemplateService extends RequestBuilder{
    _entity = 'salaryTemplateService'
    static entity = 'salaryTemplateService'
    static id = 'id' // type integer
    static ren = 'ren' // type decimal
    static renMode = 'renMode' // type string
    static salaryTemplate = 'salaryTemplate' // type salaryTemplate
    static service = 'service' // type service
}

export class Entity_Service extends RequestBuilder{
    _entity = 'service'
    static entity = 'service'
    static code = 'code' // type string
    static comment = 'comment' // type string
    static commentForClient = 'commentForClient' // type string
    static created = 'created' // type datetime
    static des = 'des' // type string
    static duration = 'duration' // type integer
    static endDay = 'endDay' // type integer
    static id = 'id' // type integer
    static isCompositePrice = 'isCompositePrice' // type boolean
    static isPriceLock = 'isPriceLock' // type boolean
    static isShowForClient = 'isShowForClient' // type boolean
    static isVisitCreateForClient = 'isVisitCreateForClient' // type boolean
    static isid = 'isid' // type string
    static name = 'name' // type string
    static optionsJson = 'optionsJson' // type json
    static priceExec = 'priceExec' // type decimal
    static priceExpend = 'priceExpend' // type decimal
    static priceTotal = 'priceTotal' // type decimal
    static resultDuration = 'resultDuration' // type integer
    static sort = 'sort' // type integer
    static startDay = 'startDay' // type integer
    static status = 'status' // type string
    static type = 'type' // type string
    static updated = 'updated' // type datetime
    static validate = 'validate' // type datetime
    static cServices = 'cServices' // type service
    static comBranch = 'comBranch' // type comBranch
    static directions = 'directions' // type direction
    static nomen = 'nomen' // type nomen
    static origServiceCat = 'origServiceCat' // type serviceCat
    static pServices = 'pServices' // type service
    static salaryServices = 'salaryServices' // type salaryService
    static salaryTemplateServices = 'salaryTemplateServices' // type salaryTemplateService
    static serviceCat = 'serviceCat' // type serviceCat
    static serviceInsurs = 'serviceInsurs' // type serviceInsur
    static serviceLabItems = 'serviceLabItems' // type serviceLabItem
    static spec = 'spec' // type spec
    static userServices = 'userServices' // type userService
    static visitServices = 'visitServices' // type visitService
}

export class Entity_ServiceCat extends RequestBuilder{
    _entity = 'serviceCat'
    static entity = 'serviceCat'
    static des = 'des' // type string
    static id = 'id' // type integer
    static isProgram = 'isProgram' // type boolean
    static isShowForClient = 'isShowForClient' // type boolean
    static isShowForOrder = 'isShowForOrder' // type boolean
    static isid = 'isid' // type string
    static name = 'name' // type string
    static sort = 'sort' // type integer
    static status = 'status' // type string
    static updated = 'updated' // type datetime
    static comBranch = 'comBranch' // type comBranch
    static comDivs = 'comDivs' // type comDiv
    static directions = 'directions' // type direction
    static origServices = 'origServices' // type service
    static serviceCat = 'serviceCat' // type serviceCat
    static serviceCats = 'serviceCats' // type serviceCat
    static services = 'services' // type service
}

export class Entity_ServiceInsur extends RequestBuilder{
    _entity = 'serviceInsur'
    static entity = 'serviceInsur'
    static code = 'code' // type string
    static contract = 'contract' // type decimal
    static dms = 'dms' // type decimal
    static id = 'id' // type integer
    static oms = 'oms' // type decimal
    static insur = 'insur' // type insur
    static service = 'service' // type service
}

export class Entity_ServiceLabItem extends RequestBuilder{
    _entity = 'serviceLabItem'
    static entity = 'serviceLabItem'
    static code = 'code' // type string
    static id = 'id' // type integer
    static labId = 'labId' // type string
    static name = 'name' // type string
    static service = 'service' // type service
}

export class Entity_Spec extends RequestBuilder{
    _entity = 'spec'
    static entity = 'spec'
    static color = 'color' // type string
    static des = 'des' // type string
    static id = 'id' // type integer
    static name = 'name' // type string
    static medRecordTemplateCategorys = 'medRecordTemplateCategorys' // type medRecordTemplateCategory
    static services = 'services' // type service
    static userSpecs = 'userSpecs' // type userSpec
    static visitPlans = 'visitPlans' // type visitPlan
    static visitServiceComposites = 'visitServiceComposites' // type visitService
}

export class Entity_User extends RequestBuilder{
    _entity = 'user'
    static entity = 'user'
    static created = 'created' // type datetime
    static firstName = 'firstName' // type string
    static id = 'id' // type integer
    static imageUrl = 'imageUrl' // type string
    static lastName = 'lastName' // type string
    static password = 'password' // type string
    static perms = 'perms' // type string
    static phone = 'phone' // type bigint
    static secondName = 'secondName' // type string
    static signatureUrl = 'signatureUrl' // type string
    static status = 'status' // type string
    static type = 'type' // type string
    static username = 'username' // type string
    static callEntryFroms = 'callEntryFroms' // type callEntry
    static callEntryTos = 'callEntryTos' // type callEntry
    static callGroupClients = 'callGroupClients' // type callGroup
    static callGroupFroms = 'callGroupFroms' // type callGroup
    static callGroupTos = 'callGroupTos' // type callGroup
    static childs = 'childs' // type user
    static dialogMessages = 'dialogMessages' // type dialogMessage
    static dialogs = 'dialogs' // type dialog
    static leadVisits = 'leadVisits' // type visit
    static logs = 'logs' // type log
    static mariedUser = 'mariedUser' // type user
    static medPlans = 'medPlans' // type medPlan
    static medRecord = 'medRecord' // type medRecord
    static medRecordAuthor = 'medRecordAuthor' // type medRecord
    static medias = 'medias' // type media
    static mediasAuthor = 'mediasAuthor' // type media
    static notifs = 'notifs' // type notif
    static operatorPays = 'operatorPays' // type pay
    static parents = 'parents' // type user
    static pays = 'pays' // type pay
    static repVisits = 'repVisits' // type visit
    static reviews = 'reviews' // type review
    static salarys = 'salarys' // type salary
    static userProfile = 'userProfile' // type userProfile
    static userServices = 'userServices' // type userService
    static userSpecMedPlans = 'userSpecMedPlans' // type medPlan
    static userSpecs = 'userSpecs' // type userSpec
    static visitPlans = 'visitPlans' // type visitPlan
    static visitServiceComposites = 'visitServiceComposites' // type visitService
    static visits = 'visits' // type visit
    static visitsAuthor = 'visitsAuthor' // type visit
    static virtual_paysCount = 'paysCount'
    static collect_paysCount = 'paysCount'
    static filterAdd_medPlanCount = 'medPlanCount'
    static filterAdd_debtor = 'debtor'
    static filterAdd_prepaid = 'prepaid'
}

export class Entity_UserProfile extends RequestBuilder{
    _entity = 'userProfile'
    static entity = 'userProfile'
    static birth = 'birth' // type date
    static certificateText = 'certificateText' // type string
    static comment = 'comment' // type string
    static commentAdd = 'commentAdd' // type string
    static contractDate = 'contractDate' // type date
    static contractNumber = 'contractNumber' // type string
    static description = 'description' // type text
    static discount = 'discount' // type integer
    static education = 'education' // type text
    static email = 'email' // type string
    static experience = 'experience' // type string
    static gender = 'gender' // type string
    static id = 'id' // type integer
    static inn = 'inn' // type string
    static instagram = 'instagram' // type string
    static insurDmsDes = 'insurDmsDes' // type string
    static insurDmsEnd = 'insurDmsEnd' // type date
    static insurOmsDes = 'insurOmsDes' // type string
    static insurOmsEnd = 'insurOmsEnd' // type date
    static isLeadBonus = 'isLeadBonus' // type boolean
    static isMainProfile = 'isMainProfile' // type boolean
    static isSelfEdit = 'isSelfEdit' // type boolean
    static isShowExternalServices = 'isShowExternalServices' // type boolean
    static medCardNumber = 'medCardNumber' // type string
    static medCardStore = 'medCardStore' // type string
    static medCardStorePosition = 'medCardStorePosition' // type string
    static oid = 'oid' // type integer
    static pasAddrRoom = 'pasAddrRoom' // type string
    static pasAddrStreet = 'pasAddrStreet' // type string
    static pasBy = 'pasBy' // type string
    static pasDate = 'pasDate' // type date
    static pasNumber = 'pasNumber' // type string
    static pasSeries = 'pasSeries' // type string
    static phone1 = 'phone1' // type bigint
    static phoneCompany = 'phoneCompany' // type bigint
    static pregnantDate = 'pregnantDate' // type date
    static pregnantPayDate = 'pregnantPayDate' // type date
    static professional = 'professional' // type text
    static promoSource = 'promoSource' // type string
    static rank = 'rank' // type string
    static resAddrEntrance = 'resAddrEntrance' // type string
    static resAddrFloor = 'resAddrFloor' // type string
    static resAddrIntercom = 'resAddrIntercom' // type string
    static resAddrRoom = 'resAddrRoom' // type string
    static resAddrStreet = 'resAddrStreet' // type string
    static snils = 'snils' // type string
    static telegram = 'telegram' // type string
    static vk = 'vk' // type string
    static whatsapp = 'whatsapp' // type string
    static work = 'work' // type text
    static workOrgName = 'workOrgName' // type string
    static workOrgPhone = 'workOrgPhone' // type bigint
    static insurDms = 'insurDms' // type insur
    static insurOms = 'insurOms' // type insur
    static passType = 'passType' // type passType
    static user = 'user' // type user
    static userProfileTags = 'userProfileTags' // type userProfileTag
}

export class Entity_UserProfileTag extends RequestBuilder{
    _entity = 'userProfileTag'
    static entity = 'userProfileTag'
    static id = 'id' // type integer
    static name = 'name' // type string
    static users = 'users' // type userProfile
}

export class Entity_UserService extends RequestBuilder{
    _entity = 'userService'
    static entity = 'userService'
    static duration = 'duration' // type integer
    static id = 'id' // type integer
    static priceTotal = 'priceTotal' // type decimal
    static service = 'service' // type service
    static user = 'user' // type user
}

export class Entity_UserSpec extends RequestBuilder{
    _entity = 'userSpec'
    static entity = 'userSpec'
    static commentAdmin = 'commentAdmin' // type string
    static id = 'id' // type integer
    static isExternalSelfVisitCreate = 'isExternalSelfVisitCreate' // type boolean
    static isSelfVisitCreate = 'isSelfVisitCreate' // type boolean
    static isShowInService = 'isShowInService' // type boolean
    static comDiv = 'comDiv' // type comDiv
    static comUserCat = 'comUserCat' // type comUserCat
    static spec = 'spec' // type spec
    static user = 'user' // type user
    static userSpecPlans = 'userSpecPlans' // type userSpecPlan
    static visitPlans = 'visitPlans' // type visitPlan
    static visits = 'visits' // type visit
}

export class Entity_UserSpecPlan extends RequestBuilder{
    _entity = 'userSpecPlan'
    static entity = 'userSpecPlan'
    static begin = 'begin' // type datetime
    static end = 'end' // type datetime
    static gridMinutes = 'gridMinutes' // type smallint
    static id = 'id' // type integer
    static status = 'status' // type string
    static type = 'type' // type string
    static comPlace = 'comPlace' // type comPlace
    static userSpec = 'userSpec' // type userSpec
}

export class Entity_Visit extends RequestBuilder{
    _entity = 'visit'
    static entity = 'visit'
    static begin = 'begin' // type datetime
    static comment = 'comment' // type string
    static created = 'created' // type datetime
    static end = 'end' // type datetime
    static id = 'id' // type integer
    static isSecond = 'isSecond' // type boolean
    static leadUserComment = 'leadUserComment' // type string
    static mark = 'mark' // type string
    static status = 'status' // type string
    static callGroup = 'callGroup' // type callGroup
    static comPlace = 'comPlace' // type comPlace
    static leadUser = 'leadUser' // type user
    static medias = 'medias' // type media
    static referral = 'referral' // type referral
    static repUser = 'repUser' // type user
    static reviews = 'reviews' // type review
    static user = 'user' // type user
    static userAuthor = 'userAuthor' // type user
    static userSpec = 'userSpec' // type userSpec
    static visitServices = 'visitServices' // type visitService
    static virtual_paySummary = 'paySummary'
}

export class Entity_VisitPlan extends RequestBuilder{
    _entity = 'visitPlan'
    static entity = 'visitPlan'
    static begin = 'begin' // type date
    static comment = 'comment' // type string
    static id = 'id' // type integer
    static isLiveList = 'isLiveList' // type boolean
    static spec = 'spec' // type spec
    static user = 'user' // type user
    static userSpec = 'userSpec' // type userSpec
}

export class Entity_VisitService extends RequestBuilder{
    _entity = 'visitService'
    static entity = 'visitService'
    static code = 'code' // type string
    static comment = 'comment' // type string
    static commentForClient = 'commentForClient' // type string
    static count = 'count' // type smallint
    static discount = 'discount' // type decimal
    static duration = 'duration' // type integer
    static endDay = 'endDay' // type integer
    static id = 'id' // type integer
    static isShowForClient = 'isShowForClient' // type boolean
    static isid = 'isid' // type string
    static medPlanSort = 'medPlanSort' // type smallint
    static mode = 'mode' // type string
    static name = 'name' // type string
    static optionsJson = 'optionsJson' // type json
    static payStatus = 'payStatus' // type string
    static price = 'price' // type decimal
    static startDay = 'startDay' // type integer
    static type = 'type' // type string
    static insur = 'insur' // type insur
    static medPlan = 'medPlan' // type medPlan
    static origServiceCat = 'origServiceCat' // type serviceCat
    static pay = 'pay' // type pay
    static service = 'service' // type service
    static serviceCat = 'serviceCat' // type serviceCat
    static specComposite = 'specComposite' // type spec
    static userComposite = 'userComposite' // type user
    static visit = 'visit' // type visit
    static visitServiceComposite = 'visitServiceComposite' // type visitService
    static visitServiceComposites = 'visitServiceComposites' // type visitService
    static virtual_startDayDate = 'startDayDate'
    static virtual_endDayDate = 'endDayDate'
    static collect_indicators = 'indicators'
}