<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:widgets>
      <v-row class="rowSmall">
        <v-col cols="4">
          <card-view title="Сумма оказанных услуг" :loading="loadState.isLoading" min-height="84">
            <template v-slot:content>
              <h2 class="font-weight-medium mb-2">{{$tools.price(collectFields.providedSum)}}</h2>
            </template>
          </card-view>
        </v-col>
        <v-col cols="4">
          <card-view title="Сумма оплаченных услуг" :loading="loadState.isLoading" min-height="84">
            <template v-slot:content>
              <h2 class="font-weight-medium mb-2">{{$tools.price(collectFields.paidSum)}}</h2>
            </template>
          </card-view>
        </v-col>
        <v-col cols="4">
          <card-view title="Сумма не оплаченных услуг" :loading="loadState.isLoading" min-height="84">
            <template v-slot:content>
              <h2 class="font-weight-medium mb-2">{{$tools.price(collectFields.unpaidSum)}}</h2>
            </template>
          </card-view>
        </v-col>
      </v-row>
    </template>
    <template v-slot:nav>
       <div class="px-4">

        <date-interval name="Интервал" :valueFrom.sync="dateFrom" :valueTo.sync="dateTo" />

        <text-field name="Наименование услуги" :value.sync="serviceName"/>

        <services-select :selectedServices.sync="selectedServices"/>

        <text-field name="id услуги в визите" :value.sync="visitServiceId"/>

        <text-field name="id Визита" :value.sync="filterByVisitId"/>
        
        <v-row class="rowSmall mb-2">
          <v-col>
            <text-field class="mb-0" name="Цена от" :value.sync="priceFrom" after-icon="mdi-currency-rub" format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="1000 или 1000.10"/>
          </v-col>
          <v-col>
            <text-field class="mb-0" name="Цена до" :value.sync="priceTo" after-icon="mdi-currency-rub" format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="1000 или 1000.10"/>
          </v-col>
        </v-row>

        <autocomplete-multiple name="Статус оплаты" :items="payStatuses" :select.sync="payStatus" textOverflow/>

        <com-branch-autocomplete-multiple :select.sync="comBranchsSelected" globalState/>

        <direction-autocomplete-multiple :select.sync="directionsSelected"/>


        <insur-select @selected="(i)=>insurSelected = i" class="mb-4"/>

        <spec-autocomplete-multiple  :select.sync="specsSelected" />

        <user-autocomplete-multiple name="Клиенты" :select.sync="userClientSelected"/>
        <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="userSpecSelcted"/>
        <user-autocomplete-multiple name="Администраторы" _type="admin" :select.sync="userAdminSelected"/>

        <referral-autocomplete-multiple name="Направляющий" :select.sync="referrerSelected" />

        <date-interval name="Дата рождения" :valueFrom.sync="userBirthFrom" :valueTo.sync="userBirthTo" />
        <autocomplete-multiple name="Пол клиента" :items="genders" :select.sync="userGender" textOverflow/>  

      </div>
    </template>
    <template v-slot:content="{height}">
      <n-table-view
          ref="table"
          name="Список"
          :genRequestBuilder="genRequestBuilder"
          :height="height"
          class="visitService"
          :headers="[
            { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap', sortable: true},
            { text: 'ID в реестре', value: 'id', align: 'start', class:'text-no-wrap', sortable: true},
            { text: 'Дата', value: 'visit.begin', class:'text-no-wrap', sortable: true },
            { text: 'Время', value: 'visit.begin', class:'text-no-wrap', sortable: false },
            { text: 'Услуга', value: 'name', class:'text-no-wrap', sortable: true},
            { text: 'Визит', value: 'visit.id', class:'text-no-wrap', sortable: true},
            { text: 'План лечения', value: 'medPlan.name', class:'text-no-wrap', sortable: true},
            { text: 'Филиал', value: 'visit.comPlace.comRoom.comFloor.comBuilding.comBranch.name', class:'text-no-wrap', sortable: true},
            { text: 'Цена', value: 'price', class:'text-no-wrap', sortable: true },
            { text: 'Количество', value: 'count', class:'text-no-wrap', sortable: true },
            { text: 'Скидка', value: 'discount', class:'text-no-wrap', sortable: true },
            { text: 'Итоговая цена', value: 'priceTotal', class:'text-no-wrap', sortable: false },
            { text: 'Направляющий', value: 'visit.referral.name', class:'text-no-wrap', sortable: true },
            { text: 'Клиент', value: 'visit.user.lastName', class:'text-no-wrap', sortable: true },
            { text: 'Специалист', value: 'visit.userSpec.user.lastName', class:'text-no-wrap', sortable: true },
            { text: 'Создал', value: 'visit.userAuthor.lastName', class:'text-no-wrap', sortable: true },
            { text: 'Организация', value: 'insur.name', class:'text-no-wrap', width: '100%', sortable: true },
            { text: 'Статус оплаты', value: 'payStatus', class:'text-no-wrap', width: '100%', sortable: true },
          ]"
      >
        <template v-slot:item="{item}">
          <tr>
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">{{item.service.id}}</td>
            <td class="text-no-wrap">{{$tools.date(item.visit.begin)}}</td>
            <td class="text-no-wrap">{{$tools.time(item.visit.begin)}}</td>
            <td class="text-wrap py-1">
              <!-- <div v-if="item.medPlan!==null" class="caption">План лечения: {{item.medPlan.name}}</div> -->
              <div>{{item.name}}</div>
              <service-code-view :code="item.code" class="mt-1"/>
            </td>
            <td>  
              <v-btn
                @click="visitId=item.visit.id;"
                small
                depressed
              >{{ item.visit.id }}</v-btn>
            </td>
            <td class="text-wrap py-1">
              <div v-if="item.medPlan!==null">{{item.medPlan.name}}</div>
              <div v-else class="text-center">-</div>
            </td>
            <td class="text-wrap py-1">             
              <div v-if="item.visit.comPlace.comRoom!==null">{{item.visit.comPlace.comRoom.comFloor.comBuilding.comBranch.name}}</div>
              <div v-else class="text-center">-</div>
            </td>
            <td class="text-no-wrap text-right">{{$tools.price(item.price)}}</td>
            <td class="text-no-wrap text-right">{{item.count}}</td>
            <td class="text-no-wrap text-right">{{$tools.price(item.discount)}}</td>
            <td class="text-no-wrap text-right">{{$tools.price(item.priceTotal)}}</td>
            <td class="text-no-wrap">
              {{ !item.visit.referral ? '-' : item.visit.referral.user ? $tools.userFullName(item.visit.referral.user) : item.visit.referral.name }}                 
              <v-btn 
                  v-if="item.visit.referral?.user"
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/client/'+item.visit.referral.user.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
            </td>
            <td class="text-no-wrap">
              {{$tools.userFullName(item.visit.user)}}
              <v-btn 
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/client/'+item.visit.user.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
            </td>
            <td class="text-no-wrap">       
              {{$tools.userFullName(item.visit.userSpec.user)}} 
              <v-btn 
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/client/'+item.visit.userSpec.user.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
            </td>
            <td class="text-no-wrap">
              <template v-if="!!item.visit.userAuthor&&!!item.visit.userAuthor.id">
                {{$tools.userFullName(item.visit.userAuthor)}} 
                <v-btn 
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/client/'+item.visit.userAuthor.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
            </td>
            <td class="text-no-wrap" v-if="item.insur">
              {{item.insur.name}}
            </td>
            <td class="text-no-wrap text-center" v-else>
              -
            </td>
            <td class="text-no-wrap">
              <status
                  :status="item.payStatus==='wait' ? 'error' : item.payStatus"
                  :text="item.payStatus==='wait' ? 'Не оплачено' : item.payStatus==='success' ? 'Оплачено' : 'Отменено'"
                  :width="100"
              />
            </td>
          </tr>
        </template>
      </n-table-view>
      <visit :visitId.sync="visitId"/>
    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import LayoutView from "@/components/LayoutView";
import NTableView from "@/componentsV2/base/NTableView.vue";
import ServiceCodeView from "@/views/Service/ServiceCodeView";
// import LabelView from "@/components/LabelView";
import moment from "moment";
import CardView from "@/components/CardView";
import Status from "@/components/Status";
import InsurSelect from "@/views/Insur/components/InsurSelect";
import ServicesSelect from "@/components/ServicesSelect.vue";
import Visit from "@/views/Visit/Visit";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import TextField from "@/componentsV2/base/TextField.vue";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import ReferralAutocompleteMultiple from "@/componentsV2/custom/ReferralAutocompleteMultiple.vue";
import SpecAutocompleteMultiple from "@/componentsV2/custom/SpecAutocompleteMultiple.vue";
import DirectionAutocompleteMultiple from "@/componentsV2/custom/DirectionAutocompleteMultiple.vue";


import {
  Entity_VisitService, 
  Entity_Insur, 
  Entity_MedPlan, 
  Entity_Service, 
  Entity_Spec, 
  Entity_User, 
  Entity_UserSpec,
  Entity_UserProfile,
  Entity_Visit, 
  Entity_ComPlace,
  Entity_ComRoom,
  Entity_ComFloor,
  Entity_ComBuilding,
  Entity_ComBranch,
  Entity_Direction,
  Entity_Referral
} from "../../../EntityStoreCacheService";

export default {
  components: {
    NTableView, 
    DateInterval,
    ComBranchAutocompleteMultiple,
    InsurSelect,
    Status,
    CardView,
    // SpecSelect,
    // UserAutocompleteMultiple,
    // LabelView, 
    ServiceCodeView, 
    LayoutView,
    ServicesSelect,
    Visit,
    TextField,
    AutocompleteMultiple,
    UserAutocompleteMultiple,
    SpecAutocompleteMultiple,
    DirectionAutocompleteMultiple,
    ReferralAutocompleteMultiple
  },
  data:()=>({
    loadState: new State(),
    dateFrom:moment().format('DD.MM.YYYY'),
    dateTo:moment().format('DD.MM.YYYY'),
    serviceName:null,
    selectedServices: [],

    payStatuses: [
      {name:'Не оплачено',id:'wait'},
      {name:'Оплачено',id:'success'},
    ],
    payStatus:[],

    priceFrom:null,
    priceTo:null,

    userBirthFrom:null,
    userBirthTo:null,
    userGender:[],

    comBranchsSelected:[],
    specsSelected:[],
    categoriesSelected:[],
    directionsSelected:[],

    visitId:null,

    visitServiceId: null,
    filterByVisitId: null,

    userClientSelected:[],
    referrerSelected:[],
    userSpecSelcted:[],
    userAdminSelected:[],

    insurSelected: [],
    genders: [{name:'Муж.',id:'male'},{name:'Жен.',id:'female'}],
    collectFields: {}
  }),
  watch:{
    dateFrom(){
      this.$refs.table.load();
    },
    dateTo(){
      this.$refs.table.load();
    },
    serviceName() {
      this.$tools.throttle(()=>{
        this.$refs.table.load();
      },700);
    },
    selectedServices(){
      this.$refs.table.load();
    },
    visitServiceId() {
      this.$tools.throttle(()=>{
        this.$refs.table.load();
      },700);
    },
    filterByVisitId(){
      this.$tools.throttle(()=>{
        this.$refs.table.load();
      },700);
    },
    priceFrom() {
      this.$tools.throttle(()=>{
        this.$refs.table.load();
      },700);
    },
    priceTo() {
      this.$tools.throttle(()=>{
        this.$refs.table.load();
      },700);
    },
    payStatus(){
      this.$refs.table.load();
    },
    comBranchsSelected() {
      this.$refs.table.load();
    },
    directionsSelected(){
      this.$refs.table.load();
    },
    insurSelected() {
      this.$refs.table.load();
    },
    specsSelected(){
      this.$refs.table.load();
    },
    userClientSelected(){     
      this.$refs.table.load();
    },
    userSpecSelcted(){     
      this.$refs.table.load();
    },
    referrerSelected(){     
      this.$refs.table.load();
    },
    userAdminSelected(){     
      this.$refs.table.load();
    },
    userGender() {
      this.$refs.table.load();
    },
    userBirthFrom() {
      this.$refs.table.load();
    },
    userBirthTo() {
      this.$refs.table.load();
    }
  },
  methods:{
    // eslint-disable-next-line no-unused-vars
    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_VisitService()
      .selects(Entity_VisitService.id)
      .selects(Entity_VisitService.name)
      .selects(Entity_VisitService.code)
      .selects(Entity_VisitService.price)
      .selects('priceTotal')
      .selects(Entity_VisitService.count)
      .selects(Entity_VisitService.discount)
      .selects(Entity_VisitService.payStatus)
      
      //service
      .selects([Entity_Service.entity, Entity_Service.id].join('.'))

      //medPlan
      .selects([Entity_MedPlan.entity, Entity_MedPlan.name].join('.'))
      
      //visit
      .selects([Entity_Visit.entity, Entity_Visit.id].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.begin].join('.'))
      .selects([Entity_Visit.entity,  Entity_Visit.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.name].join('.'))


      //user
      .selects([Entity_Visit.entity, Entity_Visit.user, Entity_User.id].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.user, Entity_User.firstName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.user, Entity_User.lastName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.user, Entity_User.secondName].join('.'))
      
      //userAuthor
      .selects([Entity_Visit.entity, Entity_Visit.userAuthor, Entity_User.id].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.userAuthor, Entity_User.firstName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.userAuthor, Entity_User.lastName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.userAuthor, Entity_User.secondName].join('.'))

      //leadUser
      .selects([Entity_Visit.entity, Entity_Visit.referral, Entity_Referral.id].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.referral, Entity_Referral.name].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.referral, Entity_Referral.user, Entity_User.firstName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.referral, Entity_Referral.user, Entity_User.lastName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.referral, Entity_Referral.user, Entity_User.secondName].join('.'))   

      //userSpec
      .selects([Entity_Visit.entity, Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.id].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.firstName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.lastName].join('.'))
      .selects([Entity_Visit.entity, Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.secondName].join('.'))

      //insur
      .selects([Entity_VisitService.insur, Entity_Insur.name].join('.'))

      .collect(Entity_VisitService.collect_indicators)


      rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.status].join('.')+'=?','complete');

      //filter by date
      if(this.dateFrom) {  
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.begin].join('.')+'>=?',moment(this.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
      } 

      if(this.dateTo) {  
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.begin].join('.')+'<=?',moment(this.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
      }
      
      //filter by service name
      if(this.serviceName) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.name].join('.')+' LIKE ?','%'+this.serviceName+'%');
      }

      //filter by service id
      if(this.selectedServices.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.service, Entity_Service.id].join('.')+' in (?)', this.queryArray(this.selectedServices));
      }

      //filter by VisitServiceId
      if(this.visitServiceId !== null) {
        rb.filterAnd([Entity_VisitService.entity, Entity_Service.id].join('.')+' = ?', this.visitServiceId);
      }

      //filter by Visit id
      if(this.filterByVisitId !== null) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.id].join('.')+' = ?', this.filterByVisitId);
      }
     
      //filter by price
      if(this.priceFrom) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.price].join('.')+' >=?',parseInt(this.priceFrom));
      }
      if(this.priceTo) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.price].join('.')+' <=?',parseInt(this.priceTo));
      }

      //filter by pay status
      if(this.payStatus.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.payStatus].join('.')+' in (?)',this.queryArray(this.payStatus));
      }
      
      //filter by combranch
      if(this.comBranchsSelected.length>0){
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id].join('.')+' in (?)',this.queryArray(this.comBranchsSelected,'id'));
      }

      //filter by directions
      if(this.directionsSelected.length>0){
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.service, Entity_Service.directions, Entity_Direction.id].join('.')+' in (?)', this.queryArray(this.directionsSelected))
      }

      //filter by insur
      if(this.insurSelected.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.insur, Entity_Insur.id].join('.')+' in (?)', this.queryArray(this.insurSelected))
      }

      //filter by spec
      if(this.specsSelected.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.userSpec, Entity_UserSpec.spec, Entity_Spec.id].join('.')+' in (?)',this.queryArray(this.specsSelected));
      }

      //filter by client
      if (this.userClientSelected.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.user,Entity_User.id].join('.')+' in (?)',this.queryArray(this.userClientSelected))
      } 

      if (this.userSpecSelcted.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.id].join('.')+' in (?)',this.queryArray(this.userSpecSelcted))
      } 

      if (this.userAdminSelected.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.userAuthor, Entity_User.id].join('.')+' in (?)',this.queryArray(this.userAdminSelected))
      } 
      
      if (this.userGender.length>0){
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.user,Entity_User.userProfile, Entity_UserProfile.gender].join('.')+' in (?)',this.queryArray(this.userGender))
      }

      //filter by referrer
      if (this.referrerSelected.length>0) {
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.referral, Entity_Referral.id].join('.')+' in (?)',this.queryArray(this.referrerSelected))
      } 
      //filter by birth date
      if(this.userBirthFrom) {  
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.user,Entity_User.userProfile, Entity_UserProfile.birth].join('.')+'>=?',moment(this.userBirthFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
      } 

      if(this.userBirthTo) {  
        rb.filterAnd([Entity_VisitService.entity, Entity_VisitService.visit, Entity_Visit.user,Entity_User.userProfile, Entity_UserProfile.birth].join('.')+'<=?',moment(this.userBirthTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
      }


      rb.page(page).onPage(onPage)


      rb.addStateCallback((v)=>{
        this.loadState.setRequestBuilderState(v)
        if(v.success)
          this.collectFields = v.collect.indicators;
      })


      sortFields.forEach(v=>{
        console.log(v)
        rb.order(v.field, v.isAsc)
      })

      return rb;
    },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    }
  }
}
</script>

<style lang="scss">
.visitService table tr{
  th, td {
    width: 100px !important;
    white-space: nowrap !important;
  }
  th:nth-child(3), td:nth-child(3) {
    width: 100% !important;
  }
}
</style>

