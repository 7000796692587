<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <portal to="tabs">
        <v-tabs v-model="tabSelected" background-color="transparent">
          <v-tab @click="$router.push('/user/client/')"><v-icon class="pr-2">mdi-account</v-icon>Клиенты</v-tab>
          <v-tab @click="$router.push('/user/spec/')"><v-icon class="pr-2">mdi-doctor</v-icon>Специалисты</v-tab>
          <v-tab @click="$router.push('/user/admin/')"><v-icon class="pr-2">mdi-account-tie</v-icon>Администраторы</v-tab>
          <v-tab @click="$router.push('/user/referral/')"><v-icon class="pr-2">mdi-account-arrow-right</v-icon>Направляющие</v-tab>
        </v-tabs>
      </portal>
      <div class="px-4">
        <v-text-field
            v-model="filter.usersSearchInput"
            label="Общий поиск"
            dense outlined hide-details="auto"
            clearable
            class="mt-1 mb-4"
            hint="Ф.И.О., телефон, email"
        />
        <v-text-field
            v-model="filter.userId"
            label="ID пользователя"
            dense outlined hide-details="auto"
            clearable
            class="mb-4"
        />

        <template v-if="$router.currentRoute.params.type!=='referral'">
          <v-text-field
              v-model="filter.userOid"
              label="Oid"
              dense outlined hide-details="auto"
              clearable
              class="mb-4"
          />
          <v-select
              label="Пол"
              v-model="filter.userGender"
              :items="[
                {name:'Любой',value:null},
                {name:'Мужчина',value:'male'},
                {name:'Женщина',value:'female'},
              ]"
              item-text="name"
              outlined dense hide-details="auto" class="my-4"
          />
          <label-view title="Возраст">
            <template v-slot:content>
              <v-row class="pt-2 px-1 mb-2">
                <v-col>
                  <v-text-field v-model="filter.userYearsFrom" clearable label="С" type="number" hide-details outlined dense/>
                </v-col>
                <v-col>
                  <v-text-field v-model="filter.userYearsTo" clearable label="По" type="number" hide-details outlined dense/>
                </v-col>
              </v-row>
            </template>
          </label-view>

          <com-branch-autocomplete-multiple :select.sync="filter.comBranchs" preLoad/>
          <spec-autocomplete-multiple v-if="$router.currentRoute.params.type!=='client'" :select.sync="filter.specs" />

          <date-interval v-if="$router.currentRoute.params.type==='client'" name="Визиты" :valueFrom.sync="filter.valueFrom" :valueTo.sync="filter.valueTo"/>

          
          <checkbox-field name="Активный медплан" class="mb-3" :value.sync="filter.hasMedPlan"/>

          <checkbox-field name="Долг" class="mb-3" :value.sync="filter.hasDebt"/>

          <checkbox-field name="Предоплата" class="mb-4" :value.sync="filter.hasPrepayment"/>

          <v-row class="px-1">
            <v-col sm="12">
              <v-btn small block depressed color="silver" @click="filterReset">
                Очистить фильтры
              </v-btn>
            </v-col>
            <v-col>
              <v-btn small block depressed color="silver" @click="isCreateOpen=true">
                Создать
              </v-btn>
            </v-col>
            <v-col>
              <v-btn small block depressed color="silver"  @click="mergeUsersModal=true">
                Объединить
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-btn small block depressed color="silver" @click="referrerCreate=true">
            Создать направляющего
          </v-btn>
        </template>
      </div>
    </template>
    <template v-slot:content="{height}">
      <n-table-view
          ref="table"
          name="Список"
          :genRequestBuilder="genRequestBuilder"
          :height="height"
          :headers="
            $router.currentRoute.params.type==='client' ? [
              { text: 'ID', value: 'id', class:'text-no-wrap'},
              { text: 'Ф.И.О.', value: 'lastName', class:'text-no-wrap' },
              { text: 'Тип', value: 'type', class:'text-no-wrap' },
              { text: 'Пол', value: 'userProfile.gender', class:'text-no-wrap' },
              { text: 'Дата рождения', value: 'userProfile.birth', class:'text-no-wrap'},
              { text: 'Мед. карта: филиал / склад / номер', value: 'userProfile.medCardNumber', class:'text-no-wrap'},
              { text: 'Телефон', value: 'phone', class:'text-no-wrap'},
              { text: 'Доп. телефон', value: 'userProfile.phone1', class:'text-no-wrap'},
              { text: 'Telegram', value: 'userProfile.telegram', class:'text-no-wrap'},
              { text: 'Email', value: 'userProfile.email', class:'text-no-wrap', width: '100%'},
            ] :
            $router.currentRoute.params.type==='spec' ? [
              { text: 'ID', value: 'id', class:'text-no-wrap'},
              { text: 'Ф.И.О.', value: 'lastName', class:'text-no-wrap' },
              { text: 'Специальности', value: 'userSpecs', class:'text-no-wrap', sortable:false },
              { text: 'Пол', value: 'userProfile.gender', class:'text-no-wrap' },
              { text: 'Дата рождения', value: 'userProfile.birth', class:'text-no-wrap'},
              { text: 'Телефон', value: 'phone', class:'text-no-wrap'},
              { text: 'Доп. телефон', value: 'userProfile.phone1', class:'text-no-wrap'},
              { text: 'Telegram', value: 'userProfile.telegram', class:'text-no-wrap'},
              { text: 'Email', value: 'userProfile.email', class:'text-no-wrap', width: '100%'},
            ] :
            $router.currentRoute.params.type==='referral' ? [
              { text: 'ID', value: 'id', class:'text-no-wrap'},
              { text: '№ контракта', value: 'contractNumber', class:'text-no-wrap'},
              { text: 'Имя', value: 'name', class:'text-no-wrap' },
              { text: 'Ф.И.О.', value: 'lastName', class:'text-no-wrap' },
              { text: 'Телефон', value: 'phone', class:'text-no-wrap'},
              { text: 'Комментарий', value: 'comment', class:'text-no-wrap'},
              { text: 'Статус', value: 'status', class:'text-no-wrap', sortable:false }
            ] :
            [
              { text: 'ID', value: 'id', class:'text-no-wrap'},
              { text: 'Ф.И.О.', value: 'lastName', class:'text-no-wrap' },
              { text: 'Специальности', value: 'userSpecs', class:'text-no-wrap', sortable:false },
              { text: 'Пол', value: 'userProfile.gender', class:'text-no-wrap' },
              { text: 'Дата рождения', value: 'userProfile.birth', class:'text-no-wrap'},
              { text: 'Телефон', value: 'phone', class:'text-no-wrap'},
              { text: 'Доп. телефон', value: 'userProfile.phone1', class:'text-no-wrap'},
              { text: 'Telegram', value: 'userProfile.telegram', class:'text-no-wrap'},
              { text: 'Email', value: 'userProfile.email', class:'text-no-wrap', width: '100%'},
            ]
          "
      >
        <template v-slot:item="{item}">
          <tr v-if="$router.currentRoute.params.type!=='referral'" :style="filter.hasDebt&&loadState.isSuccess ? {background:'#FFEBEE'} : filter.hasPrepayment&&loadState.isSuccess ? {background:'#E8F5E9'} : ''" :title="filter.hasDebt&&loadState.isSuccess ?'Есть неоплаченные услуги': null">
            <td class="text-no-wrap" @click="$router.push({ path: `/user/${$router.currentRoute.params.type}/${item.id}/` })">
              {{item.id}}
            </td>
            <td class="text-no-wrap" @click="$router.push({ path: `/user/${$router.currentRoute.params.type}/${item.id}/` })">
              <v-icon v-if="filter.hasDebt&&loadState.isSuccess" class="mr-2" color="red" small>mdi-alert-circle</v-icon> {{item.lastName}} {{item.firstName}} {{item.secondName}}
            </td>
            <td class="text-no-wrap" v-if="$router.currentRoute.params.type==='client'">{{item.type==='client' ? 'Клиент' : item.type==='spec' ? 'Специалист' : 'Администратор'}}</td>
            <td class="text-wrap" v-if="$router.currentRoute.params.type==='spec' || $router.currentRoute.params.type==='admin'">
              <v-sheet width="500" color="transparent">
                <div v-for="(userSpec,index) in item.userSpecs" :key="'userSpec'+index" class="caption">
                  {{userSpec.spec.name}}
                  <v-icon small>mdi-chevron-left</v-icon>
                  {{userSpec.comDiv.comBranch.name}}
                  <v-icon v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="ml-2" size="12">mdi-comment-text-outline</v-icon>
                  {{userSpec.commentAdmin}}

                  <v-icon v-if="userSpec.isExternalSelfVisitCreate" class="ml-2" size="14" color="primary" title="Сторонние сервисы">mdi-remote-desktop</v-icon>
                  <v-icon v-if="userSpec.isSelfVisitCreate" class="ml-2" size="14" color="primary" title="Сайт и моб. приложение">mdi-monitor-cellphone</v-icon>
                  <v-divider v-if="item.userSpecs.length-1>index"/>
                </div>
              </v-sheet>
            </td>
            <td class="text-no-wrap">
              <span v-if="item.userProfile.gender==='male'">мужчина</span>
              <span v-else>женщина</span>
            </td>
            <td class="text-no-wrap">{{$tools.date(item.userProfile.birth)}}</td>
            <td class="text-no-wrap" v-if="$router.currentRoute.params.type==='client'">
              {{$tools.isEmpty(item.userProfile.medCardStore) ? '-' : item.userProfile.medCardStore }} /
              {{$tools.isEmpty(item.userProfile.medCardStorePosition) ? '-' : item.userProfile.medCardStorePosition }} /
              {{$tools.isEmpty(item.userProfile.medCardNumber) ? item.id : item.userProfile.medCardNumber}}
            </td>
            <td class="text-no-wrap">
              <call-phone :phone="item.phone"/>
            </td>
            <td class="text-no-wrap">
              <span v-if="$tools.isEmpty(item.userProfile.phone1)">-</span>
              <call-phone v-else :phone="item.userProfile.phone1"/>
            </td>
            <td class="text-no-wrap">
              <span v-if="$tools.isEmpty(item.userProfile.telegram)">-</span>
              <link-view v-else :url="'https://t.me/'+item.userProfile.telegram" :text="item.userProfile.telegram" newWindow/>
            </td>
            <td class="text-no-wrap">
              <span v-if="$tools.isEmpty(item.userProfile.email)">-</span>
              <link-view v-else :url="'mailto:'+item.userProfile.email" :text="item.userProfile.email" newWindow/>
            </td>
          </tr>
          <!-- referral -->
          <tr v-else @click="referrerId=parseInt(item.id)">
            <td>{{ item.id }}</td>
            <td>{{ item.contractNumber }}</td>
            <td class="text-no-wrap">{{ item.name }}</td>
            <td class="text-no-wrap">{{ item.user ? $tools.userFullName(item.user) : '-' }}                 
              <v-btn 
                  v-if="item.user"
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/client/'+item.user.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
            </td>
            <td class="text-no-wrap"><call-phone :phone="item.user ? item.user.phone : item.phone"/></td>
            <td>{{ item.comment }}</td>
            <td>{{ item.status === 'active' ? 'активен' : 'неактивен' }}</td>
          </tr>
        </template>
      </n-table-view>
      <UserCreate :is-open.sync="isCreateOpen" @update:isOpen="$refs.table.load()" :userType="$router.currentRoute.params.type"/>

      <!-- merge users -->
      <merge-users :mergeUsersModal.sync="mergeUsersModal" @update="$refs.table.load()"/>

      <!-- referall -->
      <referrer-card v-if="$router.currentRoute.params.type==='referral'" :referrerId.sync="referrerId" :referrerCreate.sync="referrerCreate" @cancel="$refs.table.load();" @update="$refs.table.load()"/>

    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import UserCreate from "@/views/User/UserCreate";
import LayoutView from "@/components/LayoutView";
import LinkView from "@/components/LinkView";
import LabelView from "@/components/LabelView";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import NTableView from "@/componentsV2/base/NTableView.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";
import CallPhone from "@/components/CallPhone.vue";
import MergeUsers from "@/views/User/components/MergeUsers.vue";
import ReferrerCard from "@/views/User/components/ReferrerCard.vue";


import {
  Entity_ComBranch, Entity_ComBuilding,
  Entity_ComDiv, Entity_ComFloor, Entity_ComPlace, Entity_ComRoom, Entity_Spec,
  Entity_User,
  Entity_UserProfile,
  Entity_UserSpec, 
  Entity_Visit,
  Entity_Referral
} from "../../../EntityStoreCacheService";
import moment from "moment/moment";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import SpecAutocompleteMultiple from "@/componentsV2/custom/SpecAutocompleteMultiple.vue";

export default {
  components: {
    CallPhone,
    SpecAutocompleteMultiple,
    DateInterval,
    NTableView,
    ComBranchAutocompleteMultiple,
    LabelView, 
    LinkView, 
    LayoutView, 
    UserCreate,
    CheckboxField,
    MergeUsers,
    ReferrerCard
  },
  data: () => ({
    tabSelected:0,
    filter: {
      usersSearchInput: null,
      userId:null,
      userOid:null,
      specs:[],
      comBranchs:[],
      userGender:null,
      userYearsFrom:null,
      userYearsTo:null,
      valueFrom: null,
      valueTo: null,
      hasMedPlan:false,
      hasDebt:false,
      hasPrepayment:false,
      page: null,
      onPage: null
    },
    isCreateOpen: false,
    loadState: new State(),
    firstLoad: true,
    mergeUsersModal: false,
    referrerId: null,
    referrerCreate: false
  }),
  mounted() {
    if (this.$router.currentRoute.params.type === 'client')
      this.tabSelected=0
    else if (this.$router.currentRoute.params.type === 'spec')
      this.tabSelected=1
    else if (this.$router.currentRoute.params.type === 'referral')
      this.tabSelected=3
    else
      this.tabSelected=2

    // Получение JSON-строки из localStorage
    const filterSettings = localStorage.getItem('usersFilters');
    if (typeof localStorage.usersFilters!=='undefined'&&filterSettings!==null)
      this.filter = JSON.parse(filterSettings);
  },
  watch:{
    tabSelected(){
      this.$refs.table.load(true);
    },
    filter: {
      handler() {
        localStorage.setItem('usersFilters', JSON.stringify(this.filter));
        this.$refs.table.load();
      },
      deep: true,
    },
    'filter.hasDebt'(v) {
      this.loadState.stateLoading();
      if (v)
      this.filter.hasPrepayment=false;
    },
    'filter.hasPrepayment'(v){
      this.loadState.stateLoading();
      if (v)
      this.filter.hasDebt=false;
    }
  },
  methods: {
    genRequestBuilder(page, onPage, sortFields){

      if (this.$router.currentRoute.params.type!=='referral') {
      let rb = new Entity_User()
          .selects(Entity_User.id)
          .selects(Entity_User.lastName)
          .selects(Entity_User.firstName)
          .selects(Entity_User.secondName)
          .selects(Entity_User.phone)
          .selects(Entity_User.type)
          .selects([Entity_UserProfile.entity, Entity_UserProfile.birth].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.gender].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.email].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.phone1].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.medCardStore].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.medCardStorePosition].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.medCardNumber].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.telegram].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.commentAdmin].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.isExternalSelfVisitCreate].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.isSelfVisitCreate].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch, Entity_ComBranch.name].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.spec, Entity_Spec.name].join('.'));
          //.selects([Entity_UserProfile.entity, Entity_UserProfile.birth].join('.'))
          //.filterAnd([Entity_User.entity, Entity_User.user,Entity_User.id].join('.')+'=?', this.$router.currentRoute.params.id

          //filter sync 
          if (!this.firstLoad) {
            this.filter.page = page
            this.filter.onPage = onPage
          }

          rb.page(this.filter.page ? this.filter.page : page)
          rb.onPage(this.filter.onPage ? this.filter.onPage :onPage);
          this.firstLoad = false
      
          if (this.$router.currentRoute.params.type==='spec')
            rb.filterAnd([Entity_User.entity, Entity_User.type].join('.')+'=?', 'spec')
          else if (this.$router.currentRoute.params.type==='admin')
            rb.filterAnd([Entity_User.entity, Entity_User.type].join('.')+'=?', 'admin')

          if(!this.$tools.isEmpty(this.filter.usersSearchInput)) {
            let v = this.filter.usersSearchInput.trim();

          if (v.includes('@')) {
            rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.email].join('.') + ' LIKE ?', '%' + v + '%')
          } else if (/\d/.test(v)) {
            rb.filterAnd(
                [Entity_User.entity, Entity_User.phone].join('.') + ' LIKE ? OR ' +
                [Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.phone1].join('.') + ' LIKE ?'
                ,
                '%' + v.replace(/\D/g, '') + '%', 
                '%' + v.replace(/\D/g, '') + '%'
            )
          } else {
            let arr = v.split(' ');
            if (arr.length === 1) {
              rb.filterAnd([Entity_User.entity, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
            } else if (arr.length === 2) {
              rb.filterAnd([Entity_User.entity, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              rb.filterAnd([Entity_User.entity, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
            } else if (arr.length === 3) {
              rb.filterAnd([Entity_User.entity, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              rb.filterAnd([Entity_User.entity, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
              rb.filterAnd([Entity_User.entity, Entity_User.secondName].join('.') + ' LIKE ?', '%' + arr[2] + '%')
            }
          }
        }

        if(!this.$tools.isEmpty(this.filter.userId))
          rb.filterAnd([Entity_User.entity, Entity_User.id].join('.')+' = ?', this.filter.userId)

        if(!this.$tools.isEmpty(this.filter.userOid))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.oid].join('.')+' = ?', this.filter.userOid)

        if(!this.$tools.isEmpty(this.filter.userGender))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.gender].join('.')+' = ?', this.filter.userGender)

        if(this.$router.currentRoute.params.type==='client'){
          if(!this.$tools.isEmpty(this.filter.valueFrom))
            rb.filterAnd([Entity_User.entity, Entity_User.visits, Entity_Visit.begin].join('.')+' >= ?', moment(this.filter.valueFrom, 'DD.MM.YYYY').set({hour:0,minute:0,second:0}))
          if(!this.$tools.isEmpty(this.filter.valueTo))
            rb.filterAnd([Entity_User.entity, Entity_User.visits, Entity_Visit.begin].join('.')+' < ?', moment(this.filter.valueTo, 'DD.MM.YYYY').set({hour:0,minute:0,second:0}))
        }

        if(typeof this.filter.comBranchs!=='undefined'&&this.filter.comBranchs.length>0) {
          if(this.$router.currentRoute.params.type==='client'){
            let arr = [];
            this.filter.comBranchs.forEach(v1=>{
              arr.push(v1.id);
            })
            rb.filterAnd([
              Entity_User.entity, Entity_User.visits, Entity_Visit.comPlace, Entity_ComPlace.comRoom,
              Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id
            ].join('.') + ' IN (?)', arr)
            rb.filterAnd([Entity_User.entity, Entity_User.visits, Entity_Visit.comPlace, Entity_ComPlace.comRoom,
              Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id,
            ].join('.') + ' IS NOT NULL')
          } else {
            let arr = [];
            this.filter.comBranchs.forEach(v1=>{
              arr.push(v1.id);
            })
            rb.filterAnd([
              Entity_User.entity, Entity_User.userSpecs, Entity_UserSpec.comDiv,
              Entity_ComDiv.comBranch, Entity_ComBranch.id
            ].join('.') + ' IN (?)', arr)
            rb.filterAnd([
              Entity_User.entity, Entity_User.userSpecs, Entity_UserSpec.comDiv,
              Entity_ComDiv.comBranch, Entity_ComBranch.id
            ].join('.') + ' IS NOT NULL')
          }
        }

        if(this.filter.specs.length>0) {
          if(this.$router.currentRoute.params.type!=='client'){
            let arr = [];
            this.filter.specs.forEach(v1=>{
              arr.push(v1.id);
            })
            rb.filterAnd([
              Entity_User.entity, Entity_User.userSpecs, Entity_UserSpec.spec,
              Entity_Spec.id
            ].join('.') + ' IN (?)', arr)
          }
        }


        if(!this.$tools.isEmpty(this.filter.userYearsFrom))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.birth].join('.') + ' < ?', moment().set({hour:0,minute:0,second:0}).subtract(this.filter.userYearsFrom, 'years'))
        if(!this.$tools.isEmpty(this.filter.userYearsTo))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.birth].join('.') + ' > ?', moment().set({hour:23,minute:59,second:59}).subtract(this.filter.userYearsTo, 'years'))


        if (this.filter.hasMedPlan) {
          rb.filterAdd(['medPlanCount'].join('.'))
        }
        if (this.filter.hasDebt) {
          rb.filterAdd(['debtor'].join('.'))
        }
        if (this.filter.hasPrepayment) {
          rb.filterAdd(['prepaid'].join('.'))
        }

        rb.addStateCallback((v)=>{
          this.loadState.setRequestBuilderState(v)
        })

          
        sortFields.forEach(v=>{
          rb.order(v.field, v.isAsc)
        })

        return rb;

      } else {//referral
        let rb = new Entity_Referral()
          .selects(Entity_Referral.comment)
          .selects(Entity_Referral.contractNumber)
          .selects(Entity_Referral.phone)
          .selects(Entity_Referral.id)
          .selects(Entity_Referral.name)
          .selects(Entity_Referral.status)
          .selects(Entity_Referral.user)

          //user
          .selects([Entity_Referral.user, Entity_User.id].join('.'))
          .selects([Entity_Referral.user, Entity_User.firstName].join('.'))
          .selects([Entity_Referral.user, Entity_User.lastName].join('.'))
          .selects([Entity_Referral.user, Entity_User.secondName].join('.'))
          .selects([Entity_Referral.user, Entity_User.phone].join('.'))

          if(!this.$tools.isEmpty(this.filter.usersSearchInput)) {
            let v = this.filter.usersSearchInput.trim();

            if (v.includes('@')) {
              rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.email].join('.') + ' LIKE ?', '%' + v + '%')
            } else if (/\d/.test(v)) {
              rb.filterAnd(
                  [Entity_Referral.entity, Entity_Referral.phone].join('.') + ' LIKE ? OR ' +
                  [Entity_Referral.entity, Entity_Referral.user, Entity_User.phone].join('.') + ' LIKE ? OR ' +
                  [Entity_Referral.entity, Entity_Referral.user, Entity_User.userProfile, Entity_UserProfile.phone1].join('.') + ' LIKE ?'
                  ,
                  '%' + v.replace(/\D/g, '') + '%',
                  '%' + v.replace(/\D/g, '') + '%',
                  '%' + v.replace(/\D/g, '') + '%'
              )
            } else {
              let arr = v.split(' ');
            
              if (arr.length === 1) {
                rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              } else if (arr.length === 2) {
                rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
                rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
              } else if (arr.length === 3) {
                rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
                rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
                rb.filterAnd([Entity_Referral.entity, Entity_Referral.user, Entity_User.secondName].join('.') + ' LIKE ?', '%' + arr[2] + '%')
              }

              rb.filterOr([Entity_Referral.entity, Entity_Referral.name].join('.') + ' LIKE ?', '%' + arr[0] + '%')
            }
          }

          if(!this.$tools.isEmpty(this.filter.userId))
            rb.filterAnd([Entity_Referral.entity, Entity_Referral.id].join('.')+' = ?', this.filter.userId)


          //filter sync 
          if (!this.firstLoad) {
            this.filter.page = page
            this.filter.onPage = onPage
          }

          rb.page(this.filter.page ? this.filter.page : page)
          rb.onPage(this.filter.onPage ? this.filter.onPage :onPage);
          this.firstLoad = false


          rb.addStateCallback((v)=>{
            this.loadState.setRequestBuilderState(v)
          })

          sortFields.forEach(v=>{
            rb.order(v.field, v.isAsc)
          })

          return rb;

      }
    },
    filterReset() {
      this.filter = {
        usersSearchInput: null,
        userId:null,
        userOid:null,
        specs:[],
        userGender:null,
        userYearsFrom:null,
        userYearsTo:null,
        valueFrom: null,
        valueTo: null,
        hasMedPlan:false,
        hasDebt:false,
        hasPrepayment:false,
        page: null,
        onPage: null
      }
    }
  }
}
</script>


